<template>
  <section class="news-preview-wrapper">
    <b-row
      class="news-preview"
      v-if="
        $store.state.lawtrendStore.currentNews &&
        !$store.state.lawtrendStore.newsLoading
      "
    >
      <!-- Col: Left (News Container) -->
      <b-col cols="12" xl="9" md="8">
        <b-card>
          <h2
            v-html="$store.state.lawtrendStore.currentNews.title.rendered"
          ></h2>
          <small class="p-0 m-0 text-info"
            >{{ getDate($store.state.lawtrendStore.currentNews.date) }} ({{
              getSubDate($store.state.lawtrendStore.currentNews.date)
            }})</small
          >
          <b-img
            thumbnail
            fluid
            :src="
              $store.state.lawtrendStore.currentNews.better_featured_image
                .source_url
            "
            blank-color="#ccc"
            alt="placeholder"
            class="my-2"
          />
          <div
            v-html="$store.state.lawtrendStore.currentNews.content.rendered"
          ></div>
        </b-card>
      </b-col>
    </b-row>
    <b-card
      v-if="
        !$store.state.lawtrendStore.currentNews &&
        $store.state.lawtrendStore.newsLoading
      "
      class="text-center mt-2"
    >
      <div class="d-flex justify-content-center my-2">
        <b-spinner label="Loading..." />
      </div>
    </b-card>

    <b-card
      v-if="
        !$store.state.lawtrendStore.currentNews &&
        !$store.state.lawtrendStore.newsLoading
      "
      class="text-center mt-2"
    >
      Error, Please Try Again!
    </b-card>
  </section>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
// import store from '@/store'
import router from '@/router'
import { BRow, BCol, BCard, BCardBody, BImg, BSpinner } from 'bootstrap-vue'
import moment from 'moment'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BImg,
    BCardBody,
    BSpinner
  },
  methods: {
    getSubDate(date) {
      return moment(date).utc().fromNow()
    },
    getDate(date) {
      return moment(date).format('ddd, DD MMM, YYYY')
    },
    getData({ val }) {
      this.data = val
    }
  },
  watch: {
    '$route.params.id'(id) {
      console.log('News Id --> ', id)
      this.$store.dispatch('lawtrendStore/fetchNewsById', { id: id })
    }
  },
  beforeCreate() {
    const id = router.currentRoute.params.id
    console.log('News Id --> ', id)
    this.$store.dispatch('lawtrendStore/fetchNewsById', { id: id })
  }
}
</script>
