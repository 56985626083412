var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"news-preview-wrapper"},[(
      _vm.$store.state.lawtrendStore.currentNews &&
      !_vm.$store.state.lawtrendStore.newsLoading
    )?_c('b-row',{staticClass:"news-preview"},[_c('b-col',{attrs:{"cols":"12","xl":"9","md":"8"}},[_c('b-card',[_c('h2',{domProps:{"innerHTML":_vm._s(_vm.$store.state.lawtrendStore.currentNews.title.rendered)}}),_c('small',{staticClass:"p-0 m-0 text-info"},[_vm._v(_vm._s(_vm.getDate(_vm.$store.state.lawtrendStore.currentNews.date))+" ("+_vm._s(_vm.getSubDate(_vm.$store.state.lawtrendStore.currentNews.date))+")")]),_c('b-img',{staticClass:"my-2",attrs:{"thumbnail":"","fluid":"","src":_vm.$store.state.lawtrendStore.currentNews.better_featured_image
              .source_url,"blank-color":"#ccc","alt":"placeholder"}}),_c('div',{domProps:{"innerHTML":_vm._s(_vm.$store.state.lawtrendStore.currentNews.content.rendered)}})],1)],1)],1):_vm._e(),(
      !_vm.$store.state.lawtrendStore.currentNews &&
      _vm.$store.state.lawtrendStore.newsLoading
    )?_c('b-card',{staticClass:"text-center mt-2"},[_c('div',{staticClass:"d-flex justify-content-center my-2"},[_c('b-spinner',{attrs:{"label":"Loading..."}})],1)]):_vm._e(),(
      !_vm.$store.state.lawtrendStore.currentNews &&
      !_vm.$store.state.lawtrendStore.newsLoading
    )?_c('b-card',{staticClass:"text-center mt-2"},[_vm._v(" Error, Please Try Again! ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }